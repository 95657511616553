import React from "react";
import { motion } from "framer-motion";
import { useRouteDirection } from "../../utils/RouteDirectionContext";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import SectionFivePrestige from "./SectionFivePrestige";
import SectionSixMedical from "./SectionSixMedical";
import SectionSevenBureaux from "./SectionSevenBureaux";
import SectionEightHotel from "./SectionEightHotel";

export default function Home({ t, themeColor }) {
  const { direction, isInPage } = useRouteDirection();

  const firstVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
        delay: 0,
      },
    },
  };

  const containerVariants = {
    hidden: {
      x: direction === "right" ? "-100vw" : "100vw",
      position: "absolute",
    },
    visible: {
      x: 0,
      transition: {
        duration: 1,
        delay: 0,
      },
    },
    exit: {
      x: direction === "right" ? window.innerWidth : -window.innerWidth,
      transition: { duration: 1 },
      position: "absolute",
    },
  };

  return (
    <motion.div
      variants={!direction || isInPage ? firstVariants : containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="w-[100vw]"
    >
      <SectionOne t={t} />
      <SectionTwo t={t} themeColor={themeColor} />
      <SectionFour t={t} />
      <SectionFivePrestige t={t} themeColor={themeColor} />
      <SectionSixMedical t={t} themeColor={themeColor} />
      <SectionSevenBureaux t={t} themeColor={themeColor} />
      <SectionEightHotel t={t} themeColor={themeColor} />
      <SectionThree t={t} />
    </motion.div>
  );
}
