import React from "react";
import { motion } from "framer-motion";
import logoDark from "../../assets/images/achievements/logo2.png";
// import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export default function SectionOne({ t }) {
  // const [openColors, setOpenColors] = useState(false);

  const textVariant = {
    hidden: {
      opacity: 0,
      y: 40,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: 0.5,
      },
    },
  };

  // const colors = [
  //   "#7DF9FF", // Electric Blue
  //   "#00BFFF", // Deep Sky Blue
  //   "#87CEEB", // Sky Blue
  //   "#4682B4", // Steel Blue
  //   "#003366", // Deep Blue
  // ];

  // const [customColor, setCustomColor] = useState(
  //   localStorage.getItem("prefColor")||''
  // ); // Default custom color

  // const handleColorPick = (e) => {
  //   setCustomColor(e.target.value); // Update custom color but don't trigger onColorSelect yet
  // };

  // const applyCustomColor = () => {
  //   onColorSelect(customColor); // Only trigger onColorSelect when the user clicks 'Apply'
  // };

  return (
    <section className="sectionOne h-[80vh] w-[100vw] flex flex-col justify-center items-center text-center pb-0 mb-0">
      {/* <button
        className="absolute top-24 right-10 text-[2em]"
        onClick={() => setOpenColors((prev) => !prev)}
      >
        {openColors ? <IoIosArrowForward /> : <IoIosArrowBack />}
      </button> */}
      {/* <div className="absolute top-28 right-0">
        {openColors && (
          <div>
            <h3 className="text-atelBlack">{t("color_select_test")}</h3>
            <h3>
              {t("color_code")} {localStorage.getItem("prefColor")}
            </h3>

            <div className="flex items-center">
              {colors.map((color) => (
                <button
                  key={color}
                  onClick={() => onColorSelect(color)}
                  style={{
                    backgroundColor: color,
                    width: "30px",
                    height: "30px",
                    margin: "5px",
                    border: "none",
                  }}
                />
              ))}
              <button
                onClick={applyCustomColor} // Apply the selected color
                style={{
                  backgroundColor: customColor,
                  width: "30px",
                  height: "30px",
                  margin: "5px",
                  border: "none",
                }}
              ></button>
              <input
                type="color"
                value={customColor}
                onInput={handleColorPick} // Update color without re-rendering immediately
                style={{
                  margin: "5px",
                  width: "30px",
                  height: "30px",
                  border: "none",
                }}
              />
            </div>
          </div>
        )}
      </div> */}
      <div className="">
        <motion.p
          className="text-[2em] md:text-[4em] lg:text-[4.5em] font-bold cursor-default"
          variants={textVariant}
          initial="hidden"
          animate="visible"
        >
          {t("first_page_header")}
        </motion.p>
        <motion.p
          className="text-[1em] md:text-[2em] lg:text-[2em] cursor-default"
          variants={textVariant}
          initial="hidden"
          animate="visible"
        >
          {t("first_page_2nd_header")}
        </motion.p>

        <motion.a
          href="/"
          variants={textVariant}
          initial="hidden"
          animate="visible"
          className="logo text-atelBlack font-bold flex justify-center mt-6 md:mt-10"
        >
          <img src={logoDark} alt="logo" className="w-[65px] md:w-[80px] lg:w-[90px]" />
        </motion.a>
      </div>
    </section>
  );
}
