import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./assets/locales/en.json";
import fr from "./assets/locales/fr.json";

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    fr: {
      translation: fr,
    },
  },
  fallbackLng: "fr",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
