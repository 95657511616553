import React, { useState } from "react";
import { motion } from "framer-motion";
import Infos from "../../components/Infos";
import { useRouteDirection } from "../../utils/RouteDirectionContext";
import SectionOne from "./SectionOne";
import { Link as ScrollLink } from "react-scroll";

export default function Adadah({ t, themeColor }) {
  const { direction, isInPage } = useRouteDirection();
  const [secondHover, setSecondHover] = useState(false);


  const firstVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
        delay: 0,
      },
    },
  };

  const containerVariants = {
    hidden: {
      x: direction === "right" ? "-100vw" : "100vw",
      position: "absolute",
    },
    visible: {
      x: 0,
      transition: {
        duration: 1,
        delay: 0,
      },
    },
    exit: {
      x: direction === "right" ? window.innerWidth : -window.innerWidth,
      transition: { duration: 1 },
      position: "absolute",
    },
  };

  const textVariant = {
    hidden: {
      opacity: 0,
      y: 40,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: 0,
      },
    },
  };
  return (
    <motion.div
      variants={!direction || isInPage ? firstVariants : containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="flex flex-row h-[70vh] md:h-[80vh] w-[100vw]">
       <ScrollLink
          to={"adadah"}
          onMouseEnter={() => setSecondHover(true)}
          onMouseLeave={() => setSecondHover(false)}
          className="flex items-center justify-center bg-pg3sc1_2 bg-cover bg-center w-full cursor-pointer"
        >
          <motion.div
            variants={textVariant}
            initial="hidden"
            animate="visible"
            viewport={{ once: true }}
            className="flex justify-around items-center bg-[#7b7364]/55 w-full h-32 md:h-36 text-atelWhite"
          >
            <h1 className="text-[1.5em] md:text-[2.5em] lg:text-[3em] font-bold relative inline-block group">
              {t("third_page_sc1_header2")}
              <div
                className={`absolute left-0 bottom-0 h-1 bg-white transition-all duration-300 ${
                  secondHover ? "w-full" : "w-0"
                }`}
              ></div>
            </h1>
          </motion.div>
        </ScrollLink></div>
     <SectionOne t={t} themeColor={themeColor} />
      <Infos />
    </motion.div>
  );
}
