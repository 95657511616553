import React from "react";
import { motion } from "framer-motion";
import logoDark from "../../assets/images/logo/logoDark.png";
import logo2 from "../../assets/images/achievements/logo2.png";
import { useRouteDirection } from "../../utils/RouteDirectionContext";
import MapComponent from "../../components/MapComponent";
import { PiEnvelopeThin } from "react-icons/pi";

export default function Contact({ t, themeColor }) {
  const { direction, isInPage } = useRouteDirection();

  const firstVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
        delay: 0,
      },
    },
  };

  const containerVariants = {
    hidden: {
      x: direction === "right" ? "-100vw" : "100vw",
      position: "absolute",
    },
    visible: {
      x: 0,
      transition: {
        duration: 1,
        delay: 0,
      },
    },
    exit: {
      x: direction === "right" ? window.innerWidth : -window.innerWidth,
      transition: { duration: 1 },
      position: "absolute",
    },
  };

  const textVariant = {
    hidden: {
      opacity: 0,
      y: 40,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: 0.2,
      },
    },
  };

  return (
    <motion.div
      variants={!direction || isInPage ? firstVariants : containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="w-[100vw]"
    >
      <div>
        <div className="flex flex-col items-center md:flex-row md:justify-around h-[90vh] mt-10 md:mt-0">
          <motion.div
            variants={textVariant}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="grid place-items-center place-content-center md:w-[40vw] text-center md:h-[60%]"
          >
            <img
              src={logoDark}
              alt="logo"
              className="w-[150px] md:w-[200px] mb-4"
            />
            <div className="logo text-atelBlack font-bold flex justify-center mt-2 md:mt-2">
              <img src={logo2} alt="logo2" className="w-[60px] md:w-[70px]" />
            </div>
            <p className="mt-6">
              AVENUE KRIEG 7, <br></br>1208, GENEVE, SUISSE
            </p>
            <a href="tel:+41 22 7357007" className="mt-6">
              +41 22 7357007
            </a>
            <div className="flex gap-2 mt-6">
              <p>Lu - Ve: </p>
              <p>
                08.30-12.30 <br></br> 13.30-17.30
              </p>
            </div>
            <div className="flex justify-center items-center mt-4 mb-6">
              <a
                style={{ backgroundColor: themeColor }}
                target="_blank"
                className="flex justify-center items-center gap-4 px-6 py-3 text-atelWhite rounded-full hover:scale-105 transition-all duration-200"
                href="https://maps.google.com/maps/dir//Atelier+Herm%C3%A8s+SA+Av.+Krieg+7+1208+Gen%C3%A8ve+Switzerland/@46.1950256,6.1633828,13z/data=!4m5!4m4!1m0!1m2!1m1!1s0x478c652195b81f81:0x59165f207f62a745" rel="noreferrer"
              >
                {t("directions_btn")}
              </a>
            </div>
            <div className="flex justify-center items-center mb-8">
              <a
                href="mailto:info@atelier-hermes.ch"
                className="flex justify-center items-center gap-4 px-6 py-3 text-atelWhite rounded-full hover:scale-105 transition-all duration-200"
                style={{ backgroundColor: themeColor }}
              >
                <p className="text-[0.8em]">{t("first_page_sc5_mail_btn")}</p>
                <PiEnvelopeThin style={{ fontSize: "25px" }} />
              </a>
            </div>
          </motion.div>
          <motion.div
            variants={textVariant}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="flex justify-center items-center w-[100vw] md:w-[60vw] mt-10 md:mt-0 p-10 md:p-6"
          >
            <MapComponent />
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}
