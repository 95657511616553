import React from "react";
import { motion } from "framer-motion";
// import { PiEnvelopeThin } from "react-icons/pi";
import { Link } from "react-router-dom";

const ShowOff = ({ bg, header, custom, variant }) => {
  return (
    <motion.div
      custom={custom}
      variants={variant}
      className="h-[30vh] md:h-[40vh] w-full overflow-hidden"
    >
      <div
        className={`flex justify-center items-center ${bg} bg-cover bg-center bg-scale-hover-gall text-atelWhite h-[30vh] md:h-[40vh]`}
      >
        {header && (
          <div className="flex justify-center items-center text-center">
            <h1 className="text-[1.5em] font-semibold">{header}</h1>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default function SectionFivePrestige({ t, themeColor }) {
  const containerVariants = {
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const buttonVariants = {
    hidden: { y: 40, opacity: 0 },
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        duration: 1.5,
        delay: i * 0.2,
      },
    }),
  };
  return (
    <div>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 1.5,
          },
        }}
        viewport={{ once: true }}
        className="flex flex-col justify-center items-center bg-pg1sc4_1 bg-cover bg-center bg-scale-hover text-atelWhite h-[35vh] md:h-[50vh]"
      >
        <h1 className="text-[1.4em] md:text-[2em]">
          {t("first_page_sc4_work_header1")}
        </h1>
        <h2 className="text-[3em] md:text-[5em] font-semibold">
          {t("first_page_sc4_work_header2")}
        </h2>
      </motion.div>

      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 1.5,
          },
        }}
        viewport={{ once: true }}
        className="flex justify-center items-center text-center h-36"
      >
        <h1 className="text-[1.5em] md:text-[2.1em]">
          {t("first_page_sc5_header")}
        </h1>
      </motion.div>

      <motion.div
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        className="grid grid-cols-2 md:grid-cols-3 p-8 gap-8"
      >
        <ShowOff bg={"bg-pg1sc4_1"} custom={0} variant={buttonVariants} />
        <ShowOff bg={"bg-pg1sc5_2"} custom={1} variant={buttonVariants} />
        <ShowOff bg={"bg-pg1sc5_3"} custom={2} variant={buttonVariants} />
        <ShowOff bg={"bg-pg1sc5_4"} custom={3} variant={buttonVariants} />
        <ShowOff bg={"bg-pg1sc5_5"} custom={4} variant={buttonVariants} />
        <ShowOff bg={"bg-pg1sc5_6"} custom={5} variant={buttonVariants} />
        <ShowOff
          bg={"bg-pg1sc5_7"}
          custom={6}
          variant={buttonVariants}
          header={t("first_page_sc5_show_7")}
        />
        <ShowOff bg={"bg-pg1sc5_8"} custom={7} variant={buttonVariants} />
        <motion.div
          custom={8}
          variants={buttonVariants}
          className="flex justify-center items-center bg-atelGray h-[30vh] md:h-[40vh]"
        >
          <div
            className="flex flex-col justify-center items-center text-center"
            style={{ color: themeColor }}
          >
            <h1 className="text-[2.2em] font-semibold md:mt-14">120</h1>
            <h1 className="text-[1em] md:text-[1.5em] lg:w-64">
              {t("first_page_sc5_show_8")}
            </h1>
            <Link
              to="/news"
              className="group font-semibold mt-6 md:mt-14 text-[0.8em] md:text-[1em]"
            >
              {t("first_page_sc4_work_btn")}
              <span className="block mx-auto w-0 group-hover:w-full transition-all duration-500 h-0.5" style={{backgroundColor: themeColor}}></span>
            </Link>
          </div>
        </motion.div>
      </motion.div>

      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 1.5,
          },
        }}
        viewport={{ once: true }}
        className="flex flex-col justify-center items-center bg-pg1sc5_7 bg-cover bg-center bg-scale-hover text-atelWhite h-[35vh] md:h-[50vh]"
      >
        <h1 className="text-[1.5em] md:text-[2em] text-center">
          <strong>{t("first_page_sc5_work_header3")}</strong>
          {t("first_page_sc5_work_header4")}
        </h1>
      </motion.div>

      <motion.div
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        className="grid grid-cols-2 md:grid-cols-3 p-8 gap-8"
      >
        <ShowOff bg={"bg-pg1sc5_9"} custom={0} variant={buttonVariants} />
        <ShowOff bg={"bg-pg1sc5_10"} custom={1} variant={buttonVariants} />
        <ShowOff bg={"bg-pg1sc5_11"} custom={2} variant={buttonVariants} />
        <ShowOff bg={"bg-pg1sc5_12"} custom={3} variant={buttonVariants} />
        <ShowOff bg={"bg-pg1sc5_13"} custom={4} variant={buttonVariants} />
        <ShowOff bg={"bg-pg1sc5_14"} custom={5} variant={buttonVariants} />
      </motion.div>

      <div id="Medical"></div>

      {/* <div className="flex justify-center items-center mt-2 mb-10">
        <a
          href="mailto:info@atelier-hermes.ch"
          className="flex justify-center items-center gap-4 px-6 py-3 text-atelWhite rounded-full"
          style={{ backgroundColor: themeColor }}
        >
          <p className="text-[0.8em]">{t("first_page_sc5_mail_btn")}</p>
          <PiEnvelopeThin style={{ fontSize: "25px" }} />
        </a>
      </div> */}
    </div>
  );
}
