import React from "react";
import {
  Email,
  Instagram,
  LinkedIn,
  YouTube,
  LocalPhone,
  LocationOn,
  AccessTimeFilled,
} from "@mui/icons-material";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function Infos() {
  const { t } = useTranslation();
  const containerVariants = {
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const buttonVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
        delay: i * 0.2,
      },
    }),
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      className="grid grid-cols-2 md:grid-cols-4 place-content-center place-items-center h-[20vh] md:h-[10vh] gap-4 md:gap-8 text-[0.8em] md:text-[0.9em] lg:text-[1em]"
    >
      <motion.div
        custom={0}
        variants={buttonVariants}
        className="flex items-center gap-1 md:gap-2 md:text-center"
      >
        <LocationOn />
        <p className="text-center">
          AVENUE KRIEG 7, <br></br>1208, GENEVE, SUISSE
        </p>
      </motion.div>
      <motion.div custom={1} variants={buttonVariants}>
        <a href="tel:+41 22 7357007" className="flex items-center gap-2">
          <LocalPhone />
          +41 22 735 70 07
        </a>
      </motion.div>
      <motion.div
        custom={2}
        variants={buttonVariants}
        className="flex gap-2 ml-6 md:ml-0"
      >
        <a
          href="mailto:info@atelier-hermes.ch"
          className="flex justify-center items-center bg-atelBlack h-8 w-8 md:h-9 md:w-9 rounded-full text-atelWhite hover:scale-105 transition-all duration-200"
        >
          <Email className="w-[10px]" fontSize="small" />
        </a>
        <p
          // href="https://www.instagram.com/atelier_hermes/"
          className="flex justify-center items-center bg-atelBlack h-8 w-8 md:h-9 md:w-9 rounded-full text-atelWhite"
        >
          <Instagram fontSize="small" />
        </p>
        <p
          // href="https://www.youtube.com/@atelierhermes-genevefirasb5084"
          className="flex justify-center items-center bg-atelBlack h-8 w-8 md:h-9 md:w-9 rounded-full text-atelWhite"
        >
          <YouTube fontSize="small" />
        </p>
        <p
          // href="https://www.linkedin.com/in/firasbalboul/"
          className="flex justify-center items-center bg-atelBlack h-8 w-8 md:h-9 md:w-9 rounded-full text-atelWhite"
        >
          <LinkedIn fontSize="small" />
        </p>
      </motion.div>
      <motion.div
        custom={3}
        variants={buttonVariants}
        className="flex items-center gap-2"
      >
        <AccessTimeFilled />
        <div className="flex gap-2 text-end">
          <p>{t("footer_days")} </p>
          <p>
            08.30-12.30 <br></br> 13.30-17.30
          </p>
        </div>
      </motion.div>
    </motion.div>
  );
}
