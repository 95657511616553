import React from "react";
import { motion } from "framer-motion";
import { useRouteDirection } from "../../utils/RouteDirectionContext";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
// import SectionFourEmplyees from "./SectionFourEmplyees";
import Infos from "../../components/Infos";

export default function History({ t, themeColor }) {
  const { direction, isInPage } = useRouteDirection();

  const firstVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
        delay: 0,
      },
    },
  };

  const containerVariants = {
    hidden: {
      x: direction === "right" ? "-100vw" : "100vw",
      position: "absolute",
    },
    visible: {
      x: 0,
      transition: {
        duration: 1,
        delay: 0,
      },
    },
    exit: {
      x: direction === "right" ? window.innerWidth : -window.innerWidth,
      transition: { duration: 1 },
      position: "absolute",
    },
  };

  return (
    <motion.div
      variants={!direction || isInPage ? firstVariants : containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="w-[100vw]"
    >
      <SectionOne t={t} />
      <SectionTwo t={t} themeColor={themeColor} />
      {/* <SectionThree t={t} themeColor={themeColor} /> */}
      {/* <SectionFourEmplyees t={t} /> */}
      <Infos t={t} />
    </motion.div>
  );
}
