import React from "react";
import { motion } from "framer-motion";
// import { PiEnvelopeThin } from "react-icons/pi";
import { Link } from "react-router-dom";

const ShowOff = ({ bg, header, custom, variant }) => {
  return (
    <motion.div
      custom={custom}
      variants={variant}
      className="h-[30vh] md:h-[40vh] w-full overflow-hidden"
    >
      <div
        className={`flex justify-center items-center ${bg} bg-cover bg-center bg-scale-hover-gall text-atelWhite h-[30vh] md:h-[40vh]`}
      >
        {header && (
          <div className="flex justify-center items-center text-center">
            <h1 className="text-[1.5em] font-semibold">{header}</h1>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default function SectionSevenBureaux({ t, themeColor }) {
  const containerVariants = {
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const buttonVariants = {
    hidden: { y: 40, opacity: 0 },
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        duration: 1.5,
        delay: i * 0.2,
      },
    }),
  };

  // const offices = [
  //   "HBS Family Office - Architecture d’intérieur - Rue de la Rotisserie - Genève",
  //   "Credit Europ Bank (Suisse) - Architecture d’intérieur - Rue du Rhône 80-82",
  //   "Dynapartners Wealth Management - Architecture d’intérieur - Place du Molard - Genève",
  //   "Banque Mont-Blanc Private Banking - Architecture d’intérieur - Avenue de Champel - Genève",
  //   "Millésime Compagnie Financière - Transformation et rénovation d’un immeuble protégé en bureaux et arcade - Rue du Stand - Genève",
  //   "IAM, société financière - Architecture d’intérieur - Genève",
  //   "Banque LODH - Architecture d’intérieur - Rue De La Corraterie - Genève",
  //   "Banque de Commerce et de Placement - Architecture d’intérieur - Genève",
  //   "International Gold Corporation - Architecture d’intérieur - Genève",
  //   "Banque scandinave en Suisse SA - Architecture d’intérieur - Genève",
  //   "Banque Kleinwort et Benson - Architecture d’intérieur - Genève",
  //   "Trade Development Bank - Architecture d’intérieur - Genève",
  //   "Continental Bank - Architecture d’intérieur - Genève",
  //   "Arab Bank (Overseas) Ltd. - Architecture d’intérieur - Genève",
  //   "Gefinor Finance S.A. - Architecture d’intérieur - Quai Gustave-Ador - Genève",
  //   "Banque pour le Commerce International - Architecture d’intérieur - Genève",
  //   "Transformation Banque Romande - Architecture d’intérieur - Bvd. du Théâtre - Genève",
  //   "Etude et recherches oeuvre d’art plafond pour siège de la caisse d’Epargne de la République et Canton de Genève - Corraterie - Genève",
  //   "Bureaux de Maître E.-L. Désert - Architecture d’intérieur - Bvd. Helvétique - Genève",
  //   "Banque Cramer & Cie. - Architecture d’intérieur - Corraterie - Genève",
  // ];

  return (
    <div>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 1.5,
          },
        }}
        viewport={{ once: true }}
        className="flex flex-col justify-center items-center bg-pg1sc4_3 bg-cover bg-center bg-scale-hover text-atelWhite h-[35vh] md:h-[50vh]"
      >
        <h1 className="text-[1.4em] md:text-[2em]">
          {t("first_page_sc4_work_header1")}
        </h1>
        <h2 className="text-[3em] md:text-[5em] font-semibold text-center">
          {t("first_page_sc4_work3_header2")}
        </h2>
      </motion.div>

      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 1.5,
          },
        }}
        viewport={{ once: true }}
        className="flex justify-center items-center text-center h-36 pt-8"
      >
        <h1 className="text-[1.5em] md:text-[2.1em]">
          {t("first_page_sc7_header")}
        </h1>
      </motion.div>

      <motion.div
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        className="grid grid-cols-2 md:grid-cols-3 p-8 gap-8"
      >
        <ShowOff bg={"bg-pg1sc7_1"} custom={0} variant={buttonVariants} />
        <ShowOff bg={"bg-pg1sc7_2"} custom={1} variant={buttonVariants} />
        <motion.div
          custom={2}
          variants={buttonVariants}
          className="flex justify-center items-center bg-atelGray h-[30vh] md:h-[40vh]"
        >
          <div
            className="flex flex-col justify-center items-center text-center"
            style={{ color: themeColor }}
          >
            <h1 className="text-[2.2em] font-semibold md:mt-14">28</h1>
            <h1 className="text-[1em] md:text-[1.5em] lg:w-64">
              {t("first_page_sc7_bex_3")}
            </h1>
            <Link
              to="/news"
              className="group font-semibold mt-6 md:mt-8 text-[0.8em] md:text-[1em]"
              id="offices"
            >
              {t("first_page_sc4_work_btn")}
              <span className="block mx-auto w-0 group-hover:w-full transition-all duration-500 h-0.5" style={{backgroundColor: themeColor}}></span>
            </Link>
          </div>
        </motion.div>
      </motion.div>

      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 1.5,
          },
        }}
        viewport={{ once: true }}
        className="flex justify-center items-center md:mb-4"
      >
        {/* <a
          href="mailto:info@atelier-hermes.ch"
          className="flex justify-center items-center gap-4 px-6 py-3 text-atelWhite rounded-full"
          style={{ backgroundColor: themeColor }}
        >
          <p className="text-[0.8em]">{t("first_page_sc5_mail_btn")}</p>
          <PiEnvelopeThin style={{ fontSize: "25px" }} />
        </a> */}
      </motion.div>

      {/* <motion.div
        initial={{ y: 30, opacity: 0 }}
        whileInView={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 1.5,
          },
        }}
        viewport={{ once: true }}
        className="p-4 md:p-8 bg-white"
      >
        <h1 className="text-[1.4em] md:text-[1.5em] font-bold mb-4 text-black">
          {t("fisrt_page_sc7_bureaux_companies")}
        </h1>

        Compact grid layout
        <ul>
          {offices.map((office, index) => (
            <motion.li
              key={index}
              whileHover={{
                scale: 1.02,
                transition: { duration: 0.1 },
              }}
              className="flex items-start p-4 bg-white shadow-sm transition-transform transform hover:shadow-md cursor-default"
            >
              Compact card design
              <span className="text-black mr-2">•</span>
              <span className="md:flex items-center gap-4 font-semibold text-base text-black">
                {office.split(" - ")[0]}
                <span className="block text-sm text-gray-600">
                  {office.split(" - ").slice(1).join(" - ")}
                </span>
              </span>
            </motion.li>
          ))}
        </ul>
      </motion.div> */}
    </div>
  );
}
