import React from 'react'
import {motion} from 'framer-motion'
import { PiEnvelopeThin } from 'react-icons/pi'

export default function SectionOne({t, themeColor}) {

  return (
    <section id="adadah">
    <motion.div
      initial={{ y: 50, opacity: 0 }}
      whileInView={{
        y: 0,
        opacity: 1,
        transition: {
          duration: 1.5,
        },
      }}
      viewport={{ once: true }}
      className="flex flex-col justify-end items-start bg-pg3sc3 bg-cover bg-center bg-scale-hover text-atelWhite h-[26vh] md:h-[50vh]"
    >
      <h2 className="text-[2em] md:text-[4em] lg:text-[4.5em] font-semibold pl-6">
        {t("third_page_sc3_header")}
      </h2>
    </motion.div>

    <div className="flex md:justify-between mt-10">
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 1.5,
          },
        }}
        viewport={{ once: true }}
        className="flex flex-col justify-center items-center bg-atelGray  w-2/5 p-8 gap-8"
      >
        <div className="h-[40vh] md:h-[45vh] w-full overflow-hidden">
          <div className="bg-pg3sc3_1 bg-cover bg-center bg-scale-hover-gall h-[40vh] md:h-[45vh] p-6 w-full"></div>
        </div>
        <div className="h-[40vh] md:h-[45vh] w-full overflow-hidden">
          <div className="bg-pg3sc3_2 bg-cover bg-center bg-scale-hover-gall h-[40vh] md:h-[45vh] p-6 w-full"></div>
        </div>
        <div className="h-[40vh] md:h-[45vh] w-full overflow-hidden">
          <div className="bg-pg3sc3_3 bg-cover bg-center bg-scale-hover-gall h-[40vh] md:h-[45vh] p-6 w-full"></div>
        </div>
      </motion.div>
      <div className="flex flex-col gap-14 md:gap-20 items-start w-3/5 p-8 md:p-16">
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
            transition: {
              duration: 1.5,
            },
          }}
          viewport={{ once: true }}
          className="flex flex-col justify-center items-center gap-6 text-[0.8em] md:text-[1em] md:w-[80%] cursor-default"
        >
          <p>{t("third_page_sc3_text1")}</p>

          <p>{t("third_page_sc3_text2")}</p>

          <p>{t("third_page_sc3_text3")}</p>
        </motion.div>

        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
            transition: {
              duration: 1.5,
            },
          }}
          viewport={{ once: true }}
          className="flex justify-center items-center"
        >
          <a
            href="mailto:info@atelier-hermes.ch"
            className="flex justify-center items-center gap-4 px-4 py-1 md:px-6 md:py-3 text-atelWhite rounded-full hover:scale-105 transition-all duration-200"
            style={{ backgroundColor: themeColor }}
          >
            <p className="text-[0.5em] md:text-[0.8em]">
              {t("first_page_sc5_mail_btn")}
            </p>
            <PiEnvelopeThin style={{ fontSize: "25px" }} />
          </a>
        </motion.div>
      </div>
    </div>
    <div id="ourteam"></div>
  </section>
  )
}
