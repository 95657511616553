import React from "react";
import { motion } from "framer-motion";
import ico1 from "../../assets/images/performance/ico1.png";
import ico2 from "../../assets/images/performance/ico2.png";
import ico3 from "../../assets/images/performance/ico3.png";
import ico4 from "../../assets/images/performance/ico4.png";

const Services = ({ icon, header, text, custom, variant }) => {
  return (
    <motion.div
      custom={custom}
      variants={variant}
      className="flex flex-col justify-center items-center text-atelWhite"
    >
      <div className="h-[100px] md:h-[150px] mb-6 md:mb-0 md:pb-6 hover:scale-110 duration-300 cursor-pointer">
        <img src={icon} alt="icon" />
      </div>
      <h1 className="lg:text-[1.1em] font-semibold text-center h-12">
        {header}
      </h1>
      <p className="p-4 text-justify text-[0.9em] lg:text-[1em]">{text}</p>
    </motion.div>
  );
};

export default function SectionTwo({ t, themeColor }) {
  const containerVariants = {
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const buttonVariants = {
    hidden: { y: 40, opacity: 0 },
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        duration: 1.5,
        delay: i * 0.2,
      },
    }),
  };

  return (
    <section id="architecture">
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 1.5,
          },
        }}
        viewport={{ once: true }}
        className="flex flex-col justify-end items-start bg-pg2sc2_1 bg-cover bg-center bg-scale-hover text-atelWhite h-[26vh] md:h-[50vh] pl-6"
      >
        <h2 className="text-[2em] md:text-[4em] lg:text-[4.5em] font-semibold">
          {t("second_page_sc2_header")}
        </h2>
      </motion.div>

      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 1.5,
          },
        }}
        viewport={{ once: true }}
        className="flex justify-center items-center text-center md:h-36 p-8 my-0 md:my-4"
      >
        <h1 className="text-[1.1em] md:text-[1.3em]" id="services">
          {t("second_page_sc2_text")}
        </h1>
      </motion.div>

      <div className="flex flex-col justify-around items-center bg-[#d3cfbf] ">
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
            transition: {
              duration: 1.5,
            },
          }}
          viewport={{ once: true }}
          className="flex justify-center items-center p-6"
        >
          <h1 className="text-atelWhite text-[1.5em] font-semibold">
            {t("second_page_sc2_services")}
          </h1>
        </motion.div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="grid grid-cols-2 md:grid-cols-4 place-items-start place-content-center place-self-center"
        >
          <Services
            icon={ico1}
            header={t("second_page_sc2_services1_header")}
            text={t("second_page_sc2_services1_text")}
            custom={0}
            variant={buttonVariants}
          />
          <Services
            icon={ico2}
            header={t("second_page_sc2_services2_header")}
            text={t("second_page_sc2_services2_text")}
            custom={1}
            variant={buttonVariants}
          />
          <Services
            icon={ico3}
            header={t("second_page_sc2_services3_header")}
            text={t("second_page_sc2_services3_text")}
            custom={2}
            variant={buttonVariants}
          />
          <Services
            icon={ico4}
            header={t("second_page_sc2_services4_header")}
            text={t("second_page_sc2_services4_text")}
            custom={3}
            variant={buttonVariants}
          />
        </motion.div>
      </div>
    </section>
  );
}
