import React from "react";
import { motion } from "framer-motion";
import img1 from "../../assets/images/history/fb.jpg";
import img2 from "../../assets/images/history/atelieri.png";

export default function SectionTwo({ t, themeColor }) {
  return (
    <section id="history">
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 1.5,
          },
        }}
        viewport={{ once: true }}
        className="flex flex-col justify-end items-start bg-pg3sc2 bg-cover bg-center bg-scale-hover text-atelWhite h-[26vh] md:h-[50vh]"
      >
        <h2 className="text-[2em] md:text-[4em] lg:text-[4.5em] font-semibold pl-6">
          {t("third_page_sc1_header")}
        </h2>
      </motion.div>

      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 1.5,
          },
        }}
        viewport={{ once: true }}
        className="flex justify-center items-center text-center md:h-36 p-8 my-0 md:my-4"
      >
        <h1 className="text-[1.1em] md:text-[1.3em]">
          {t("third_page_sc2_text")}
        </h1>
      </motion.div>

      <div className="flex justify-between">
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
            transition: {
              duration: 1.5,
            },
          }}
          viewport={{ once: true }}
          className="w-2/5"
        >
          <div className="bg-atelGray flex flex-col justify-start items-center p-6 gap-6">
            <div className="h-[35vh] md:h-[45vh] w-full overflow-hidden">
              <div className="flex items-end bg-pg3sc2_1 bg-cover bg-left-bottom bg-scale-hover-gall md:bg-left h-[35vh] md:h-[45vh] w-full">
                <p className="text-atelBrown bg-atelGray pt-1 pr-1 text-[0.5em] md:text-[0.7em] lg:text-[1em]">
                  <strong>Enrich Hermès</strong>
                  {t("third_page_sc2_his1")}
                </p>
              </div>
            </div>
            <div className="h-[35vh] md:h-[45vh] w-full overflow-hidden">
              <div className="bg-pg3sc2_2 bg-cover bg-center bg-scale-hover-gall h-[35vh] md:h-[45vh] w-full"></div>
            </div>
            <div className="h-[35vh] md:h-[45vh] w-full overflow-hidden">
              <div className="bg-pg3sc2_3 bg-cover bg-center bg-scale-hover-gall h-[35vh] md:h-[45vh] w-full"></div>
            </div>
            <div className="h-[35vh] md:h-[45vh] w-full overflow-hidden">
              <div className="flex items-end justify-end bg-pg3sc2_4 bg-cover bg-bottom bg-scale-hover-gall h-[35vh] md:h-[45vh] w-full">
                <p className="text-atelBrown bg-atelGray pt-1 pl-1 text-[0.5em] md:text-[0.7em] lg:text-[1em]">
                  <strong>Patrick Hermès</strong>
                  {t("third_page_sc2_his4")}
                </p>
              </div>
            </div>
            <div className="h-[35vh] md:h-[45vh] w-full overflow-hidden">
              <div className="bg-pg3sc2 bg-cover bg-center bg-scale-hover-gall h-[35vh] md:h-[45vh] w-full"></div>
            </div>
          </div>
        </motion.div>

        <div className="flex flex-col items-start w-3/5">
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: {
                duration: 1.5,
              },
            }}
            viewport={{ once: true }}
            className="flex flex-col gap-6"
          >
            <div className=" p-2 md:p-4 lg:p-6 bg-white max-w-4xl mx-auto lg:w-[90%] cursor-default">
              <div className="w-full">
                <h1 className="text-start text-[0.9em] md:text-lg lg:text-2xl font-bold mb-4">
                  {t("third_page_sc2_his1_header")}
                </h1>
              </div>

              <p className="mb-4 text-[0.7em] md:text-[0.9em] lg:text-[1em] float-left">
                <strong className="font-bold" style={{ color: themeColor }}>
                  Erich Hermès
                </strong>
                {t("third_page_sc2_his1_text1")}
              </p>
              <div className="mt-4 float-right">
                <img
                  src={img2}
                  alt="Atelier Hermès"
                  className="w-28 md:w-48 lg:w-64 h-auto"
                />
              </div>
              <div>
                <div className="text-[0.7em] md:text-[0.9em] lg:text-[1em]">
                  <p className="mb-4">{t("third_page_sc2_his1_text2")}</p>

                  <p className="mb-4">
                    {t("third_page_sc2_his1_text33")}
                    <strong className="font-bold" style={{ color: themeColor }}>
                      Luc Hermès
                    </strong>
                    {t("third_page_sc2_his1_text3")}
                  </p>
                </div>
              </div>
            </div>

            <motion.div className="p-2 md:p-4 lg:p-6 bg-white max-w-4xl mx-auto lg:mt-14 lg:w-[90%] cursor-default">
              <div className="w-full">
                <h1 className="text-[0.9em] md:text-lg lg:text-2xl font-bold mb-4">
                  {t("third_page_sc2_his2_header")}
                </h1>
              </div>

              <p className="mb-4 text-[0.7em] md:text-[0.9em] lg:text-[1em]">
                <strong className="font-bold" style={{ color: themeColor }}>
                  Patrick Hermès
                </strong>
                {t("third_page_sc2_his2_text1")}
              </p>
              <p className="mb-4 text-[0.7em] md:text-[0.9em] lg:text-[1em]">
                {t("third_page_sc2_his2_text2")}
              </p>
              <div className="flex flex-col justify-center items-center gap-4 p-2 md:p-4 float-right bg-atelGray">
                <img
                  src={img1}
                  alt="Firas Balboul"
                  className="w-24 md:w-48 lg:w-64 h-auto"
                />
                <p className="text-atelBrown text-[0.5em] md:text-[0.8em] w-24 md:w-48 lg:w-full">
                  <strong>Firas Balboul</strong>, {t("third_page_sc2_director")}
                </p>
              </div>
              <div>
                <div className="text-[0.7em] md:text-[0.9em] lg:text-[1em]">
                  <p className="mb-4">{t("third_page_sc2_his2_text3")}</p>

                  <p className="mb-4">{t("third_page_sc2_his2_text4")}</p>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
