import React from "react";
import { motion } from "framer-motion";
import pt1 from "../../assets/images/achievements/1.png";
import pt2 from "../../assets/images/achievements/2.png";
import pt3 from "../../assets/images/achievements/3.png";
import pt4 from "../../assets/images/achievements/4.png";
import pt5 from "../../assets/images/achievements/5.png";
import pt6 from "../../assets/images/achievements/6.png";
import pt7 from "../../assets/images/achievements/7.png";
import pt8 from "../../assets/images/achievements/8.png";
import pt9 from "../../assets/images/achievements/9.png";
import pt10 from "../../assets/images/achievements/10.png";
import pt11 from "../../assets/images/achievements/11.png";
import pt12 from "../../assets/images/achievements/12.png";
import pt13 from "../../assets/images/achievements/13.png";
import pt14 from "../../assets/images/achievements/14.png";
import pt15 from "../../assets/images/achievements/15.png";
import pt16 from "../../assets/images/achievements/16.png";
import pt17 from "../../assets/images/achievements/17.png";
import pt18 from "../../assets/images/achievements/18.png";
import pt19 from "../../assets/images/achievements/19.png";
import pt20 from "../../assets/images/achievements/20.png";
import Infos from "../../components/Infos";

export default function SectionThree({ t }) {
  const textVariant = {
    hidden: {
      opacity: 0,
      y: 40,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: 0,
      },
    },
  };

  const partnerVariant = {
    hidden: {
      opacity: 0,
      y: 40,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: 0.5,
      },
    },
  };

  // const scrollToElement = (id) => {
  //   const element = document.getElementById(id);
  //   if (element) {
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  return (
    <div>
      <div className="flex flex-col justify-center items-center cursor-default">
        <motion.p
          variants={textVariant}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="text-[1.4em] text-center pt-10"
        >
          {t("first_page_sc3_partners")}
        </motion.p>
      </div>
      <motion.div
        variants={partnerVariant}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        className="grid grid-cols-5 gap-2 place-items-center pt-10 pb-8"
      >
        <img src={pt2} alt="partner" width={220} />
        <img src={pt20} alt="partner" width={220} />
        <img src={pt10} alt="partner" width={220} />
        <img src={pt3} alt="partner" width={220} />
        <img src={pt11} alt="partner" width={220} />
        <img src={pt5} alt="partner" width={220} />
        <img src={pt13} alt="partner" width={220} />
        <img src={pt12} alt="partner" width={220} />
        <img src={pt14} alt="partner" width={220} />
        <img src={pt15} alt="partner" width={220} />
        <img src={pt19} alt="partner" width={220} />
        <img src={pt4} alt="partner" width={220} />
        <img src={pt18} alt="partner" width={220} />
        <img src={pt17} alt="partner" width={220} />
        <img src={pt16} alt="partner" width={220} />
        <img src={pt9} alt="partner" width={220} />
        <img src={pt8} alt="partner" width={220} />
        <img src={pt7} alt="partner" width={220} />
        <img src={pt6} alt="partner" width={220} />
        <img src={pt1} alt="partner" width={220} />
      </motion.div>
{/* 
      <div className="flex flex-col justify-center items-center cursor-default">
        <motion.p
          variants={textVariant}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="group text-[1.2em] text-center pb-4 cursor-pointer"
          onClick={() => scrollToElement("offices")}
        >
          {t("redirect_to_companies_title")}
          <span className="block mx-auto w-0 group-hover:w-full transition-all duration-500 h-0.5 bg-black"></span>
        </motion.p>
      </div> */}

      <Infos />
    </div>
  );
}
