import React from "react";
import { motion } from "framer-motion";
import { Link as ScrollLink } from "react-scroll";

const Work = ({ bg, header, header2, btn, custom, variant, href }) => {
  return (
    <motion.div
      custom={custom}
      variants={variant}
      className="h-[35vh] md:h-[40vh] overflow-hidden"
    >
      <div
        className={`flex justify-center items-center ${bg} bg-cover bg-center bg-scale-hover-gall text-atelWhite h-[35vh] md:h-[40vh]`}
      >
        <div className="flex flex-col justify-center items-center bg-[#5e5c58]/50 w-2/3 text-center">
          <h1 className="text-[0.8em] md:text-[1.1em] mt-8">{header}</h1>
          <h1 className="text-[1em] md:text-[2.2em] lg:text-[3em] font-semibold w-[90%]">
            {header2}
          </h1>
          <ScrollLink
            to={href}
            duration={1000}
            className="group font-semibold mb-4 mt-4 md:mt-0 text-[0.5em] md:text-[1em] cursor-pointer"
          >
            {btn}
            <span className="block mx-auto w-0 group-hover:w-full transition-all duration-500 h-0.5 bg-white"></span>
          </ScrollLink>
        </div>
      </div>
    </motion.div>
  );
};

export default function SectionFour({ t }) {
  const containerVariants = {
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const buttonVariants = {
    hidden: { y: 40, opacity: 0 },
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        duration: 1.5,
        delay: i * 0.2,
      },
    }),
  };

  return (
    <>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        className="grid grid-cols-2 gap-4 p-[1rem] h-80vh mb-8 cursor-default"
      >
        <Work
          bg={"bg-pg1sc4_1"}
          header={t("first_page_sc4_work_header1")}
          header2={t("first_page_sc4_work_header2")}
          btn={t("first_page_sc4_work_btn")}
          custom={0}
          variant={buttonVariants}
          href="Prestige"
        />
        <Work
          bg={"bg-pg1sc4_2"}
          header={t("first_page_sc4_work_header1")}
          header2={t("first_page_sc4_work2_header2")}
          btn={t("first_page_sc4_work_btn")}
          custom={1}
          variant={buttonVariants}
          href="Medical"
        />
        <Work
          bg={"bg-pg1sc4_3"}
          header={t("first_page_sc4_work_header1")}
          header2={t("first_page_sc4_work3_header2")}
          btn={t("first_page_sc4_work_btn")}
          custom={2}
          variant={buttonVariants}
          href="Bureaux"
        />
        <Work
          bg={"bg-pg1sc4_4"}
          header={t("first_page_sc4_work_header1")}
          header2={t("first_page_sc4_work4_header2")}
          btn={t("first_page_sc4_work_btn")}
          custom={3}
          variant={buttonVariants}
          href="Hotel"
        />
      </motion.div>
      <div id="Prestige" className="mb-20"></div>
    </>
  );
}
