import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link as ScrollLink } from "react-scroll";

export default function SectionOne({ t }) {
  const textVariant = {
    hidden: {
      opacity: 0,
      y: 40,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: 0.2,
      },
    },
  };

  const [archHover, setArchHover] = useState(false);
  const [fmaHover, setFmaHover] = useState(false);

  return (
    <section>
      <div className="flex items-center justify-center h-[80vh] md:h-[90vh] w-[100vw] bg-pg2sc1 bg-cover bg-center">
        <motion.div
          variants={textVariant}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="flex justify-around items-center bg-[#7b7364]/55 w-full h-32 md:h-36 text-atelWhite cursor-default"
        >
          <ScrollLink
            onMouseEnter={() => setArchHover(true)}
            onMouseLeave={() => setArchHover(false)}
            to="architecture"
            className="text-[1.3em] md:text-[2em] lg:text-[3em] font-bold ml-6 md:ml-0 relative inline-block group cursor-pointer"
          >
            {t("second_page_sc1_header")}
            <div
              className={`absolute left-0 md:bottom-2 h-1 bg-white transition-all duration-300 ${
                archHover ? "w-full" : "w-0"
              }`}
            ></div>
          </ScrollLink>
          <ScrollLink
            onMouseEnter={() => setFmaHover(true)}
            onMouseLeave={() => setFmaHover(false)}
            to="fma360"
            className="text-[1.3em] md:text-[2em] lg:text-[3em] font-bold relative inline-block group cursor-pointer"
          >
            {t("second_page_sc1_header2")}
            <div
              className={`absolute left-0 md:bottom-2 h-1 bg-white transition-all duration-300 ${
                fmaHover ? "w-full" : "w-0"
              }`}
            ></div>
          </ScrollLink>
        </motion.div>
      </div>
    </section>
  );
}
