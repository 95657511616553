import React from "react";
import { motion } from "framer-motion";
import Infos from "../../components/Infos";
import { Instagram } from "@mui/icons-material";

export default function SectionOne({ t }) {
  const textVariant = {
    hidden: {
      opacity: 0,
      y: 40,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: 0.2,
      },
    },
  };

  return (
    <section>
      <div className="flex items-center justify-center h-[70vh] md:h-[80vh] w-[100vw] bg-pg4sc1 bg-cover bg-center">
        <motion.div
          variants={textVariant}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="flex flex-col justify-center items-center w-full h-32 text-atelWhite"
        >
          <Instagram
            fontSize="250px"
            className="text-[150px] md:text-[220px]"
          />
          <a
            // href="https://www.instagram.com/atelier_hermes/"
            href="/"
            className="group text-[1.2em] font-bold"
          >
            {t("first_page_sc4_work_btn")}
            <span className="block mx-auto w-0 group-hover:w-full transition-all duration-500 h-0.5 bg-atelWhite"></span>
          </a>
        </motion.div>
      </div>

      <Infos />
    </section>
  );
}
