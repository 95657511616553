import React from "react";

const MapComponent = () => {
  return (
    <div style={{ height: "70vh", width: "100%" }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d32601.73692724553!2d6.153744815008753!3d46.19841702229274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478c652195b81f81%3A0x59165f207f62a745!2sAtelier%20Herm%C3%A8s%20SA!5e1!3m2!1sen!2s!4v1727292518816!5m2!1sen!2s"
        width="100%"
        height="100%"
        style={{ border: "0" }}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        allowFullScreen=""
        title="Google Maps"
      ></iframe>
    </div>
  );
};

export default MapComponent;
