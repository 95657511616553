import React, { useEffect, useRef, useState } from "react";
import { useRouteDirection } from "../utils/RouteDirectionContext";
import { useLocation, useNavigate } from "react-router-dom";
import logoDark from "../assets/images/logo/logoDark.png";
import { AnimatePresence, motion } from "framer-motion";

export const Button = ({ name, location, setOpen, custom, variants }) => {
  const { determineDirection } = useRouteDirection();
  const { pathname } = useLocation();

  return (
    <motion.button
      custom={custom}
      variants={variants}
      onClick={() => {
        determineDirection(`${location}`);
        if (setOpen) {
          setOpen(false);
        }
      }}
      className={`text-atelBlack px-3 py-2 rounded-md border-none text-[1em] md:text-[0.7em] lg:text-[0.9em] ${
        location === pathname ? "font-extrabold" : "font-normal"
      }`}
    >
      {name}
    </motion.button>
  );
};

export default function Header({ t, changeLanguage, setIsOverflowHidden }) {
  const { determineDirection } = useRouteDirection();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [lng, setLng] = useState("fr");

  const handleLanguageChange = () => {
    const newLng = lng === "fr" ? "en" : "fr";
    setLng(newLng);
    changeLanguage(newLng);
  };

  const [open, setOpen] = useState(false);
  // const [dropdownVisible, setDropdownVisible] = useState(false);
  const navbarRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const containerVariants = {
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const buttonVariants = {
    hidden: { y: 10, opacity: 0 },
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: i * 0.2,
      },
    }),
  };

  const menuAnimation = {
    hidden: {
      x: "20vh",
      opacity: 0,
      transition: { duration: 0.3 },
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: { duration: 0.4 },
    },
  };

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    // setDropdownVisible(false);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleLinkDropdown = (path) => {
    const pathToIdMap = {
      history: { path: "/history", id: "history" },
      adadah: { path: "/history", id: "adadah" },
      ourteam: { path: "/history", id: "ourteam" },
      services: { path: "/services", id: "services" },
      fma360: { path: "/services", id: "fma360" },
    };

    const { path: targetPath, id } = pathToIdMap[path];

    if (pathname === targetPath) {
      scrollToElement(id);
    } else {
      navigate(targetPath);
      setTimeout(() => scrollToElement(id), 800);
    }
  };
  setIsOverflowHidden(open)

  return (
    <div>
      <div className="h-[10vh] md:hidden max-w-[100%]" ref={navbarRef}>
        <div className="fixed flex justify-around items-center h-24 w-full md:hidden z-20 bg-white">
          <a href="/" className="logo text-atelBlack font-bold">
            <img src={logoDark} alt="logo" className="w-20" />
          </a>
          <div className="flex gap-4 z-20">
            <motion.button
              onClick={handleLanguageChange}
              className="text-atelBlack text-[1.2em]"
              custom={5}
              variants={buttonVariants}
            >
              {lng === "en" ? "FR" : "EN"}
            </motion.button>
            <button
              onClick={() => setOpen((prev) => !prev)}
              id="menu-btn"
              className={`${
                open && "open"
              } block hamburger focus:outline-none outline-none mt-[7px]`}
            >
              <span className="hamburger-top bg-atelBlack"></span>
              <span className="hamburger-middle bg-atelBlack"></span>
              <span className="hamburger-bottom bg-atelBlack"></span>
            </button>
          </div>
        </div>

        <AnimatePresence>
          {open && (
            <div className="fixed w-full backdrop-blur-sm bg-gray-200/10 z-10">
              <motion.div
                variants={menuAnimation}
                initial="hidden"
                animate="visible"
                className="float-right bg-white h-[100vh] w-60 pt-32 md:hidden z-10 m-0"
                // onMouseLeave={() => setDropdownVisible(false)}
              >
                <div className="text-atelBlack">
                  <div className="flex flex-col items-start self-center pl-2 gap-4 tracking-wider font-[600] text-lg">
                    <motion.div
                      className="relative"
                      custom={0}
                      variants={buttonVariants}
                    >
                      <button
                        className={`flex items-center text-atelBlack px-3 py-2 rounded-md border-none text-[1em] md:text-[1.2em] lg:text-[1.2em] ${
                          "/" === pathname ? "font-extrabold" : "font-normal"
                        }`}
                      >
                        <p
                          onClick={() => {
                            determineDirection(`/`);
                            if (setOpen) {
                              setOpen(false);
                            }
                          }}
                        >
                          {t("nav_menu_link_achievements")}
                        </p>
                        {/* <svg
                        className="w-4 h-4 ms-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                        onClick={() => setDropdownVisible((prev) => !prev)}
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg> */}
                      </button>

                      {/* {dropdownVisible && (
                      <motion.div
                        initial="hidden"
                        animate={"visible"}
                        variants={containerVariants}
                        className="absolute flex flex-col left-0 bg-white rounded-md shadow-lg z-10 font-thin text-[0.8em] whitespace-nowrap"
                      >
                        <motion.div
                          custom={0}
                          variants={buttonVariants}
                          onClick={() => {
                            handleLinkDropdown("fma360");
                            if (setOpen) {
                              setOpen(false);
                            }
                          }}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        >
                          {t("nav_menu_link_services_drop1")}
                        </motion.div>

                        <motion.div
                          custom={1}
                          variants={buttonVariants}
                          onClick={() => {
                            handleLinkDropdown("history");
                            if (setOpen) {
                              setOpen(false);
                            }
                          }}
                          className="px-4 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer"
                        >
                          {t("nav_menu_link_services_drop2")}
                        </motion.div>

                        <motion.div
                          custom={2}
                          variants={buttonVariants}
                          onClick={() => {
                            handleLinkDropdown("services");
                            if (setOpen) {
                              setOpen(false);
                            }
                          }}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        >
                          {t("nav_menu_link_services_drop3")}
                        </motion.div>
                      </motion.div>
                    )} */}
                    </motion.div>
                    <Button
                      name={t("nav_menu_link_services")}
                      location={"/services"}
                      setOpen={setOpen}
                      variants={buttonVariants}
                      custom={1}
                    />
                    <motion.button
                      custom={2}
                      variants={buttonVariants}
                      onClick={() => {
                        determineDirection("/services");
                        handleLinkDropdown("fma360");
                        if (setOpen) {
                          setOpen(false);
                        }
                      }}
                      className={`text-atelBlack px-3 py-2 rounded-md border-none text-[1em] md:text-[1.2em] lg:text-[1.2em] ${
                        "/services" === pathname
                          ? "font-extrabold"
                          : "font-normal"
                      }`}
                    >
                      {t("nav_menu_link_services_drop1")}
                    </motion.button>
                    <motion.button
                      custom={3}
                      location={"/services"}
                      variants={buttonVariants}
                      onClick={() => {
                        determineDirection("/services");
                        handleLinkDropdown("services");
                        if (setOpen) {
                          setOpen(false);
                        }
                      }}
                      className={`text-left text-atelBlack px-3 py-2 rounded-md border-none text-[1em] md:text-[1.2em] lg:text-[1.2em] ${
                        "/services" === pathname
                          ? "font-extrabold"
                          : "font-normal"
                      }`}
                    >
                      {t("nav_menu_link_services_drop3")}
                    </motion.button>
                    <Button
                      name={t("nav_menu_link_services_drop2")}
                      location={"/history"}
                      setOpen={setOpen}
                      custom={4}
                      variants={buttonVariants}
                    />
                    {/* <motion.button
                    custom={5}
                    variants={buttonVariants}
                    onClick={() => {
                      determineDirection("/history");
                      handleLinkDropdown("history");
                      if (setOpen) {
                        setOpen(false);
                      }
                    }}
                    className={`text-left text-atelBlack px-3 py-2 rounded-md border-none text-[1em] md:text-[1.2em] lg:text-[1.2em] ${
                      "/history" === pathname ? "font-extrabold" : "font-normal"
                    }`}
                  >
                    {t("nav_menu_link_services_drop2")}
                  </motion.button> */}
                    {/* <motion.button
                    custom={5}
                    variants={buttonVariants}
                    onClick={() => {
                      determineDirection('/history');
                      handleLinkDropdown("adadah");
                      if (setOpen) {
                        setOpen(false);
                      }
                    }}
                    className={`text-left text-atelBlack px-3 py-2 rounded-md border-none text-[1em] md:text-[1.2em] lg:text-[1.2em] ${
                      "/history" === pathname ? "font-extrabold" : "font-normal"
                    }`}
                  >
                    {t("nav_menu_link_services_drop_adah")}
                  </motion.button> */}
                    <Button
                      name={t("nav_menu_link_services_drop_adah")}
                      location={"/adadah"}
                      setOpen={setOpen}
                      custom={5}
                      variants={buttonVariants}
                    />
                    <Button
                      name={t("nav_menu_link_news")}
                      location={"/news"}
                      setOpen={setOpen}
                      custom={6}
                      variants={buttonVariants}
                    />
                    <Button
                      name={t("nav_menu_link_contact")}
                      location={"/contact"}
                      setOpen={setOpen}
                      custom={7}
                      variants={buttonVariants}
                    />
                  </div>
                </div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      </div>

      <div className="h-[10vh] hidden md:flex">
        <div className="flex justify-between items-center w-full min-h-[10vh] fixed z-10 bg-white">
          <a
            href="/"
            className="logo flex justify-center items-center text-atelBlack font-bold pl-6 py-4"
          >
            <img src={logoDark} alt="logo" className="w-20" />
          </a>
          <nav>
            <div className="container mx-auto px-4">
              <div className="flex h-16 items-center justify-between">
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={containerVariants}
                  className="flex items-center pr-4"
                >
                  <motion.div
                    custom={0}
                    variants={buttonVariants}
                    className="relative"
                    // onMouseEnter={() => setDropdownVisible(true)}
                    // onMouseLeave={() => setDropdownVisible(false)}
                  >
                    <button
                      onClick={() => {
                        determineDirection(`/`);
                        if (setOpen) {
                          setOpen(false);
                        }
                      }}
                      className={`flex items-center text-atelBlack px-3 py-2 rounded-md border-none text-[1em] md:text-[0.7em] lg:text-[0.9em] ${
                        "/" === pathname ? "font-extrabold" : "font-normal"
                      }`}
                    >
                      {t("nav_menu_link_achievements")}
                      {/* <svg
                        className="w-3 h-3 ms-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg> */}
                    </button>

                    {/* {dropdownVisible && (
                      <motion.div
                        initial="hidden"
                        animate={"visible"}
                        variants={containerVariants}
                        onMouseEnter={() => setDropdownVisible(true)}
                        onMouseLeave={() => setDropdownVisible(false)}
                        className="absolute flex flex-col left-0 bg-white rounded-md shadow-lg z-10 text-[0.9em] md:text-[1em] whitespace-nowrap"
                      >
                        <motion.div
                          custom={0}
                          variants={buttonVariants}
                          onClick={() => handleLinkDropdown("fma360")}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        >
                          {t("nav_menu_link_services_drop1")}
                        </motion.div>

                        <motion.div
                          custom={1}
                          variants={buttonVariants}
                          onClick={() => handleLinkDropdown("history")}
                          className="px-4 py-2 hover:bg-gray-100 whitespace-nowrap cursor-pointer"
                        >
                          {t("nav_menu_link_services_drop2")}
                        </motion.div>

                        <motion.div
                          custom={2}
                          variants={buttonVariants}
                          onClick={() => handleLinkDropdown("services")}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        >
                          {t("nav_menu_link_services_drop3")}
                        </motion.div>
                      </motion.div>
                    )} */}
                  </motion.div>

                  <motion.div custom={1} variants={buttonVariants}>
                    <Button
                      name={t("nav_menu_link_services")}
                      location={"/services"}
                    />
                  </motion.div>

                  <motion.div custom={2} variants={buttonVariants}>
                    <button
                      onClick={() => {
                        determineDirection("/services");
                        handleLinkDropdown("fma360");
                        if (setOpen) {
                          setOpen(false);
                        }
                      }}
                      className={`text-atelBlack px-3 py-2 rounded-md border-none text-[1em] md:text-[0.7em] lg:text-[0.9em] ${
                        "/services" === pathname
                          ? "font-extrabold"
                          : "font-normal"
                      }`}
                    >
                      {t("nav_menu_link_services_drop1")}
                    </button>
                  </motion.div>
                  <motion.div custom={3} variants={buttonVariants}>
                    <button
                      onClick={() => {
                        determineDirection("/services");
                        handleLinkDropdown("services");
                        if (setOpen) {
                          setOpen(false);
                        }
                      }}
                      className={`text-left text-atelBlack px-3 py-2 rounded-md border-none text-[1em] md:text-[0.7em] lg:text-[0.9em] ${
                        "/services" === pathname
                          ? "font-extrabold"
                          : "font-normal"
                      }`}
                    >
                      {t("nav_menu_link_services_drop3")}
                    </button>
                  </motion.div>

                  <motion.div custom={4} variants={buttonVariants}>
                    <Button
                      name={t("nav_menu_link_services_drop2")}
                      location={"/history"}
                    />
                  </motion.div>
                  {/* <motion.div custom={4} variants={buttonVariants}>
                    <button
                      onClick={() => {
                        determineDirection("/history");
                        handleLinkDropdown("history");
                        if (setOpen) {
                          setOpen(false);
                        }
                      }}
                      className={`text-left text-atelBlack px-3 py-2 rounded-md border-none text-[1em] md:text-[0.7em] lg:text-[0.9em] ${
                        "/history" === pathname
                          ? "font-extrabold"
                          : "font-normal"
                      }`}
                    >
                      {t("nav_menu_link_services_drop2")}
                    </button>
                  </motion.div> */}
                  {/* <motion.div custom={4} variants={buttonVariants}>
                    <button
                      onClick={() => {
                        determineDirection('/history');
                        handleLinkDropdown("adadah");
                        if (setOpen) {
                          setOpen(false);
                        }
                      }}
                      className={`text-left text-atelBlack px-3 py-2 rounded-md border-none text-[1em] md:text-[0.7em] lg:text-[0.9em] ${
                        "/history" === pathname
                          ? "font-extrabold"
                          : "font-normal"
                      }`}
                    >
                      {t("nav_menu_link_services_drop_adah")}
                    </button>
                  </motion.div> */}
                  <motion.div custom={5} variants={buttonVariants}>
                    <Button
                      name={t("nav_menu_link_services_drop_adah")}
                      location={"/adadah"}
                    />
                  </motion.div>
                  <motion.div custom={6} variants={buttonVariants}>
                    <Button name={t("nav_menu_link_news")} location={"/news"} />
                  </motion.div>
                  <motion.div custom={7} variants={buttonVariants}>
                    <Button
                      name={t("nav_menu_link_contact")}
                      location={"/contact"}
                    />
                  </motion.div>
                  <motion.div custom={8} variants={buttonVariants}>
                    <button
                      onClick={handleLanguageChange}
                      className="text-atelBlack px-3 py-2 rounded-md md:text-[0.7] lg:text-[0.9em]"
                    >
                      {lng === "en" ? "FR" : "EN"}
                    </button>
                  </motion.div>
                </motion.div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
