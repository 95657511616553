import React from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import med1 from "../../../assets/images/achievements/med1.jpg";
import hotel from "../../../assets/images/achievements/hotel.jpg";
import bur from "../../../assets/images/achievements/bureaux.jpg";
import sl2 from "../../../assets/images/achievements/sl2.jpg";
import Infos from "../../../components/Infos";

// Delete these posts and imported images upon configuring the api!
const posts = [
  {
    id: 1,
    headline: "Post 1 Headline",
    date: "Fri Jun 19 2020",
    category: "Category",
    imageUrl: `${med1}`,
    bigText:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    id: 2,
    headline: "Post 2 Headline",
    date: "Fri Jun 19 2020",
    category: "Category",
    imageUrl: `${hotel}`,
    bigText:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    id: 3,
    headline: "Post 3 Headline",
    date: "Fri Jun 19 2020",
    category: "Category",
    imageUrl: `${bur}`,
    bigText:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    id: 4,
    headline: "Post 4 Headline",
    date: "Fri Jun 19 2020",
    category: "Category",
    imageUrl: `${sl2}`,
    bigText:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
];

export default function MoreDetails({ t }) {
  const { id } = useParams();
  const post = posts.find((post) => post.id === parseInt(id));
  const { key, imageUrl, headline, date, category, bigText } = post;

  if (!post) {
    return <div>{t("fourth_page_sc2_notFound")}</div>;
  }

  const firstVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
        delay: 1,
      },
    },
  };

  return (
    <motion.section
      variants={firstVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="w-[100vw]"
    >
      <div className="flex justify-center items-center mt-24">
        <h1 className="text-2xl text-atelBlack font-semibold">
          {t("fourth_page_sc2_moreDetails")}
        </h1>
      </div>
      <div className="flex justify-center mb-10">
        <div className="p-6 md:w-2/3">
          <div key={key} className="bg-white p-4 shadow-md flex flex-col">
            <img
              src={imageUrl}
              alt={headline}
              className="h-48 w-full object-cover mb-4 object-center"
            />
            <div className="text-gray-600 text-sm mb-2">
              {date} | {category}
            </div>
            <h2 className="text-xl font-bold mb-2">{headline}</h2>
            <p className="text-gray-700 mb-4">{bigText}</p>
          </div>
        </div>
      </div>
      <Infos />
    </motion.section>
  );
}
