import React from "react";
import { motion } from "framer-motion";
import { PiEnvelopeThin } from "react-icons/pi";
import Infos from "../../components/Infos";

export default function SectionThree({ t, themeColor }) {
  const containerVariants = {
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const buttonVariants = {
    hidden: { y: 40, opacity: 0 },
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        duration: 1.5,
        delay: i * 0.2,
      },
    }),
  };

  return (
    <section id="fma360">
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 1.5,
          },
        }}
        viewport={{ once: true }}
        className="flex flex-col justify-end items-start bg-pg1sc5_5 bg-cover bg-center bg-scale-hover text-atelWhite h-[26vh] md:h-[50vh]"
      >
        <h2 className="text-[2em] md:text-[4em] lg:text-[4.5em] font-semibold pl-6">
          {t("second_page_sc3_header")}
        </h2>
      </motion.div>

      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 1.5,
          },
        }}
        viewport={{ once: true }}
        className="flex justify-center items-center p-4 bg-atelGray mb-10"
      >
        <h1 className="text-atelBrown md:text-[1.5em] font-semibold">
          {t("second_page_sc3_header2")}
        </h1>
      </motion.div>

      <div className="flex md:justify-between">
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
            transition: {
              duration: 1.5,
            },
          }}
          viewport={{ once: true }}
          className="flex flex-col justify-center items-center bg-atelGray h-[70vh] md:h-[90vh] w-2/5 p-8 gap-8"
        >
          <div className="h-[40vh] md:h-[45vh] w-full overflow-hidden">
            <div className="bg-pg2sc3_1 bg-cover bg-center bg-scale-hover-gall h-[40vh] md:h-[45vh] p-6 w-full"></div>
          </div>
          <div className="h-[40vh] md:h-[45vh] w-full overflow-hidden">
            <div className="bg-pg2sc3_2 bg-cover bg-center bg-scale-hover-gall h-[40vh] md:h-[45vh] p-6 w-full"></div>
          </div>
        </motion.div>
        <div className="flex flex-col md:justify-around items-start w-3/5 p-8 md:p-16">
          <motion.div
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="flex flex-col justify-center items-center gap-6 text-[0.8em] md:text-[1em] md:w-[80%]"
          >
            <motion.p custom={0} variants={buttonVariants}>
              {t("second_page_sc3_text1")}
            </motion.p>
            <motion.p custom={1} variants={buttonVariants}>
              {t("second_page_sc3_text2")}
            </motion.p>
            <motion.p custom={2} variants={buttonVariants}>
              {t("second_page_sc3_text3")}
            </motion.p>
          </motion.div>
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: {
                duration: 1.5,
              },
            }}
            viewport={{ once: true }}
            className="flex justify-center items-center"
          >
            <a
              href="mailto:info@atelier-hermes.ch"
              className="flex justify-center items-center gap-4 px-4 py-1 md:px-6 md:py-3 mt-6 md:mt-0 text-atelWhite rounded-full hover:scale-105 transition-all duration-200"
              style={{ backgroundColor: themeColor }}
            >
              <p className="text-[0.6em] md:text-[0.8em]">
                {t("first_page_sc5_mail_btn")}
              </p>
              <PiEnvelopeThin style={{ fontSize: "25px" }} />
            </a>
          </motion.div>
        </div>
      </div>
      <Infos />
    </section>
  );
}
