import React, { useState } from "react";
import { motion } from "framer-motion";
// import Infos from "../../components/Infos";
import { Link as ScrollLink } from "react-scroll";

export default function SectionOne({ t }) {
  const textVariant = {
    hidden: {
      opacity: 0,
      y: 40,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: 0,
      },
    },
  };

  const [firstHover, setFirstHover] = useState(false);

  return (
    <section>
      <div className="flex flex-row h-[70vh] md:h-[80vh] w-[100vw]">
        <ScrollLink
          to={"history"}
          onMouseEnter={() => setFirstHover(true)}
          onMouseLeave={() => setFirstHover(false)}
          className="flex items-center justify-center bg-pg3sc1 bg-cover bg-left w-full cursor-pointer"
        >
          <motion.div
            variants={textVariant}
            initial="hidden"
            animate="visible"
            viewport={{ once: true }}
            className="flex justify-around items-center bg-[#7b7364]/55 w-full h-32 md:h-36 text-atelWhite"
          >
            <h1 className="text-[1.5em] md:text-[2.5em] lg:text-[3em] font-bold relative inline-block group ml-4 md:ml-0">
              {t("third_page_sc1_header")}
              <div
                className={`absolute left-0 bottom-0 h-1 bg-white transition-all duration-300 ${
                  firstHover ? "w-full" : "w-0"
                }`}
              ></div>
            </h1>
          </motion.div>
        </ScrollLink>
       
      </div>

      {/* <Infos /> */}
    </section>
  );
}
