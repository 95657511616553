import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { motion } from "framer-motion";
import sl1 from "../../assets/images/achievements/sl1.jpg";
import sl2 from "../../assets/images/achievements/sl2.jpg";
import sl3 from "../../assets/images/achievements/sl3.jpg";
import sl4 from "../../assets/images/achievements/sl4.png";
import sl5 from "../../assets/images/achievements/sl5.jpg";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link as ScrollLink } from "react-scroll";

export default function SectionTwo({ t, themeColor }) {
  const slideData = [
    {
      img: sl1,
      header: t("first_page_slide_1_header"),
      header2: t("first_page_slide_1_header2"),
      text: t("first_page_slide_1_text"),
      btn: `${t("first_page_slide_discover")}`,
    },
    {
      img: sl2,
      header2: t("first_page_slide_1_header"),
      text: t("first_page_slide_1_text"),
      btn: `${t("first_page_slide_discover")}`,
    },
    {
      img: sl3,
      header: t("first_page_slide_3_header"),
      header2: t("first_page_slide_3_header2"),
      text: t("first_page_slide_3_text"),
      btn: `${t("first_page_slide_discover")}`,
    },
    {
      img: sl4,
      header: t("first_page_slide_3_header"),
      header2: t("first_page_slide_4_header2"),
      text: t("first_page_slide_4_text"),
      btn: `${t("first_page_slide_discover")}`,
    },
    {
      img: sl5,
      header: t("first_page_slide_3_header"),
      header2: t("first_page_slide_5_header2"),
      text: t("first_page_slide_5_text"),
      btn: `${t("first_page_slide_discover")}`,
    },
  ];

  const textVariant = {
    hidden: {
      opacity: 0,
      x: 40,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
        delay: 0,
      },
    },
  };

  const projectsVariant = {
    hidden: {
      opacity: 0,
      y: 40,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: 0.5,
      },
    },
  };

  return (
    <section>
      <motion.div
        variants={projectsVariant}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        className="flex flex-col justify-center items-center pb-8 cursor-default"
      >
        <p className="text-[2em] font-semibold" style={{ color: themeColor }}>
          394
        </p>
        <p className="text-[1.5em]">{t("first_page_sc3_project")}</p>
      </motion.div>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        speed={2000}
        modules={[Pagination, Autoplay]}
        className="mySwiper h-[50vh] md:h-[90vh]"
      >
        {slideData.map((slide, index) => {
          return (
            <SwiperSlide
              style={{
                backgroundImage: `url(${slide.img})`,
                backgroundPosition: "center",
              }}
              className="bg-cover"
              key={index}
            >
              <div className="flex items-center h-full">
                <motion.div
                  variants={textVariant}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: false }}
                  className="flex flex-col justify-center bg-[#5e5c58]/40 h-full w-[37vw] md:w-[30vw] cursor-default"
                >
                  <div
                    className={`text-end text-atelWhite ${
                      index >= 2 ? "ml-6" : ""
                    } md:ml-28 w-[250px] md:w-[390px]`}
                  >
                    <h1
                      className={`text-[1.2em] md:text-[2.3em] lg:text-[3em] md:h-[60px] tracking-wider text-nowrap ${
                        index > 1 ? "font-normal" : "font-extrabold"
                      } `}
                      style={{ textShadow: "#393939 0px 0px 17px" }}
                    >
                      {slide.header}
                    </h1>
                    {slide.header2 && (
                      <h1
                        className={`text-[1.2em] md:text-[2.3em] lg:text-[3em] md:h-[60px] tracking-wider text-nowrap ${
                          index === 2 ? "mr-14 md:mr-0" : ""
                        } ${index < 1 ? "font-normal" : "font-extrabold"}`}
                        style={{ textShadow: "#393939 0px 0px 17px" }}
                      >
                        {slide.header2}
                      </h1>
                    )}
                  </div>
                  <div className="flex flex-col items-end text-end text-atelWhite pr-1">
                    <p className="text-[0.8em] md:text-[1.2em]">{slide.text}</p>
                    <ScrollLink
                      to="OurWork"
                      duration={2000}
                      className="group text-end pt-4 text-[0.6em] md:text-[0.9em] font-semibold cursor-pointer"
                    >
                      <div className="flex items-center">
                        {slide.btn} <MdKeyboardArrowRight className="text-[11px] md:text-[19px]"/>
                      </div>
                      <span className="block mx-auto w-0 group-hover:w-full transition-all duration-500 h-0.5 bg-white"></span>
                    </ScrollLink>
                  </div>
                </motion.div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div id="OurWork"></div>
    </section>
  );
}
