import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import Achievements from "./pages/Achievements/Achievements";
import Performance from "./pages/Performance/Performance";
import History from "./pages/History/History";
import Adadah from "./pages/Adadah/Adadah";
import News from "./pages/News/News";
import Contact from "./pages/Contact/Contact";
import Header from "./components/Header";
import { AnimatePresence, motion } from "framer-motion";
import { useRouteDirection } from "./utils/RouteDirectionContext";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/pagination";
import ScrollToTop from "./utils/ScrollToTop";
import logoLight from "./assets/images/logo/logoLight.png";
import logoDark from "./assets/images/logo/logoDark.png";
import { useState, useEffect, lazy } from "react";
import MoreDetails from "./pages/News/posts/MoreDetails";
const Error = lazy(() => import("./pages/Error"));

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { determineDirection } = useRouteDirection();
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [isOverflowHidden, setIsOverflowHidden] = useState(false);

  ScrollToTop();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1200);
    return () => clearTimeout(timer);
  }, []);

  const themeColor = "#00374a";

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        // Always set overflow to auto when width is more than 768px
        document.body.style.overflow = "auto";
      } else {
        // Update overflow based on the state when width is less than or equal to 768px
        document.body.style.overflow = isOverflowHidden ? "hidden" : "auto";
      }
    };

    // Attach the resize listener
    window.addEventListener("resize", handleResize);

    // Run the function initially to set the correct overflow
    handleResize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isOverflowHidden]);

  // const handleColorSelect = (color) => {
  //   setThemeColor(color);
  //   localStorage.setItem("prefColor", color);
  //   document.documentElement.style.setProperty("--primary-color", color);
  //   window.location.reload();
  // };

  return (
    <>
      <AnimatePresence>
        {isLoading && (
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 3 } }}
            style={{ backgroundColor: themeColor }}
            className="flex flex-col justify-center items-center w-[100vw] h-[100svh] fixed z-50"
          >
            <motion.img
              exit={{ y: -100, transition: { duration: 1.5, delay: 1.5 } }}
              src={logoLight}
              alt="logo"
              className="w-[280px] md:w-[400px] lg:w-[500px]"
            />
            <motion.h1
              exit={{ y: -70, transition: { duration: 2.1, delay: 1.5 } }}
              className="text-atelWhite text-[1.5em] lg:text-[2em]"
            >
              1965
            </motion.h1>
          </motion.div>
        )}
      </AnimatePresence>

      {!isLoading && (
        <>
          <Header
            determineDirection={determineDirection}
            t={t}
            changeLanguage={changeLanguage}
            setIsOverflowHidden={setIsOverflowHidden}
          />
          <AnimatePresence mode="sync" onExitComplete={false}>
            <Routes location={location} key={location.key}>
              <Route
                path="*"
                element={
                  <Error t={t} logo={logoDark} themeColor={themeColor} />
                }
              />
              <Route
                path="/"
                element={
                  <Achievements
                    t={t}
                    // onColorSelect={handleColorSelect}
                    themeColor={themeColor}
                  />
                }
              />
              <Route
                path="/services"
                element={<Performance t={t} themeColor={themeColor} />}
              />
              <Route
                path="/history"
                element={<History t={t} themeColor={themeColor} />}
              />
              <Route
                path="/adadah"
                element={<Adadah t={t} themeColor={themeColor} />}
              />
              <Route
                path="/news"
                element={<News t={t} themeColor={themeColor} />}
              />
              <Route
                path="/contact"
                element={<Contact t={t} themeColor={themeColor} />}
              />
              <Route
                path="/details/:id"
                element={<MoreDetails t={t} themeColor={themeColor} />}
              />
            </Routes>
          </AnimatePresence>
        </>
      )}
    </>
  );
}

export default App;
