// RouteDirectionContext.js
import React, { createContext, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const RouteDirectionContext = createContext();

export const useRouteDirection = () => {
  return useContext(RouteDirectionContext);
};

export const RouteDirectionProvider = ({ children }) => {
  const [direction, setDirection] = useState();
  const [isInPage, setIsInPage] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const determineDirection = (path) => {
    const routes = [
      "/",
      "/services",
      "/history",
      "/adadah",
      "/news",
      "/contact",
    ];
    const currentIndex = routes.indexOf(location.pathname);
    const nextIndex = routes.indexOf(path);

    if (nextIndex > currentIndex) {
      setDirection("left");
    } else {
      setDirection("right");
    }
    if (
      (location.pathname === "/" && path === "/") ||
      (location.pathname === "/services" && path === "/services") ||
      (location.pathname === "/history" && path === "/history") ||
      (location.pathname === "/adadah" && path === "/adadah") ||
      (location.pathname === "/news" && path === "/news") ||
      (location.pathname === "/contact" && path === "/contact")
    ) {
      setIsInPage(true);
    } else {
      setIsInPage(false);
    }
    navigate(path);
  };

  return (
    <RouteDirectionContext.Provider
      value={{ direction, determineDirection, isInPage }}
    >
      {children}
    </RouteDirectionContext.Provider>
  );
};
